import * as React from "react"
import styled from "styled-components";


import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from '../components/Title';
import Image from '../components/Image';

const Box = styled.div`
  @media(max-width: 1000px){
    padding: 0 20px;
  }
`;

const Container = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;

  p{
    margin-right:20px;
  }
  h4 {
    margin-left: 10px;
  }
  img{
    height: 31px;
    width: 31px;
  }
  .featurette-SubTitulo {
    font-size: 24px;
  }

  @media(max-width: 1000px){
    flex-direction: column;
    align-items: center;

    img{
      margin-top:20px;
      width:80%;
    }
  }
`;

const IndexPage = () => (
  <Layout>
    <Seo title="Nuestros Servicios" />
    <Box>
      <Title title='Nuestros' secondTitle='Servicios' />
  
      <p>Es una empresa dedicada a entregar soluciones. Hasta ahora se ha centrado prioritariamente en el campo de las cadenas de negocios, con experiencias y capacidad para efectuar trabajos de cualquier nivel y en cualquier ámbito.
      <br />Ha desarrollado sus actividades en óptimas condiciones, desde el diseño, fabricación y mantención, también hemos desarrollando trabajos en el área de obras civiles menores como son la construcción, reparación y montaje de cortinas metálicas, pintura, gasfitería, electricidad y mantención de obras civiles. 
      <br /><br />Entre otros servicios realizamos el montaje y la mantención de material publicitario desde Rancagua hasta Puerto Montt para la Empresa SUPERMERCADOS  UNIMARC , MAXIAHORRO ,MAYORISTA 10,DIPAC, ALVI ,SANTA ISABEL, JUMBO, PHILIP MORRIS CHILE LTDA,CRUZ VERDE SA, PETRIZZIO, VENDOME, OK MARKET construcción y montaje de estructuras de aluminio y vidrios ,para distintas entidades publicas. Durante este periodo, ESOV se ha caracterizado siempre por su continuo desarrollo, adaptación y responsabilidad a las modernas tecnologías que paralelamente han ido naciendo, situándose siempre a la vanguardia de las empresas y microempresas.
      </p>
     
      <Container>
        <Image srcName="tit_servicios.png" width="31px" height="31px" alt="Construcción, Reparación" />
        <h4 class="featurette-SubTitulo">Construcción, Reparación</h4>
      </Container>
      

      <Container>
        <Image srcName="tit_servicios.png" width="31px" height="31px" alt="Montaje de Cortinas Metálicas" />
        <h4 class="featurette-SubTitulo">Montaje de Cortinas Metálicas</h4>
      </Container>
      

      <Container>
        <Image srcName="tit_servicios.png" width="31px" height="31px" alt="Mantención de Obras Civiles" />
        <h4 class="featurette-SubTitulo">Mantención de Obras Civiles</h4>
      </Container>
      

      <Container>
        <Image srcName="tit_servicios.png" width="31px" height="31px" alt="Mantención de material publicitario" />
        <h4 class="featurette-SubTitulo">Mantención de material publicitario</h4>
      </Container>
      

      <Container>
        <Image srcName="tit_servicios.png" width="31px" height="31px" alt="Construcción y montaje de estructuras de aluminio y vidrios" />
        <h4 class="featurette-SubTitulo">Construcción y montaje de estructuras de aluminio y vidrios</h4>
      </Container>
      

      <Container>
        <Image srcName="tit_servicios.png" width="31px" height="31px" alt="Pintura" />
        <h4 class="featurette-SubTitulo">Pintura</h4>
      </Container>
      

      <Container>
        <Image srcName="tit_servicios.png" width="31px" height="31px" alt="Gasfitería" />
        <h4 class="featurette-SubTitulo">Gasfitería</h4>
      </Container>
      

      <Container>
        <Image srcName="tit_servicios.png" width="31px" height="31px" alt="Electricidad" />
        <h4 class="featurette-SubTitulo">Electricidad</h4>
      </Container>
      

      <Container>
        <Image srcName="tit_servicios.png" width="31px" height="31px" alt="Soldadura en acero dulce e inoxidable" />
        <h4 class="featurette-SubTitulo">Soldadura en acero dulce e inoxidable</h4>
      </Container>
      

      <Container>
        <Image srcName="tit_servicios.png" width="31px" height="31px" alt="Armado y Mantencion de muebles  en linea plana" />
        <h4 class="featurette-SubTitulo">Armado y Mantencion de muebles  en linea plana</h4>
      </Container>
    
    </Box>
  </Layout>
)

export default IndexPage
